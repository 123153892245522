/* eslint-disable no-console */
import { Splash } from './modules/splash';

import './styles';

async function init() {
  const splash = new Splash();

  try {
    const boot = await import(/* webpackChunkName: 'boot' */ './boot');
    await boot.start();
    splash.fade();
  } catch (err) {
    splash.error(err);
    console.error(err.stack || err);
  }
}

init().catch(err => {
  console.error('Failed to load app', err);
});
