/* eslint-disable @typescript-eslint/no-explicit-any */
import { querySelector } from 'utils/dom';
import './Splash.scss';

// NOTE: This module loads raw SCSS files *on purpose*, because since we want
// this to be loaded as early as possible, utilising as little client-side
// JavaScript as possible, so this is one of the very few modules we shouldn't
// rewrite into CSS-in-JS.

export class Splash {
  private element = querySelector<HTMLDivElement>('.kata-splash');

  private splashText = querySelector<HTMLSpanElement>('.kata-splash__loading-text');

  private raf: number | undefined;

  constructor() {
    this.animateSplashText();
  }

  public fade() {
    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }

    this.element.style.transition = '0.3s';
    this.element.style.opacity = '0';
    this.element.style.visibility = 'hidden';
  }

  public error(err: any) {
    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }

    const message = querySelector<HTMLDivElement>('.kata-splash__error-details');

    message.innerText = err;

    this.element.classList.add('kata-splash--hasError');
  }

  private animateSplashText() {
    let previous = 0;
    let ellipsisCount = 1;

    const animate = (time: number) => {
      if (!previous || time - previous > 500) {
        previous = time;
        ellipsisCount = ellipsisCount >= 3 ? 1 : ellipsisCount + 1;
      }

      this.splashText.innerText = `Loading${Array(ellipsisCount).fill('.').join('')}`;
      this.raf = requestAnimationFrame(animate);
    };

    this.raf = requestAnimationFrame(animate);
  }
}
